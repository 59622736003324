<template>
  <div class="app">
    <!-- <nav>
      <router-link to="/">Home</router-link>
      <router-link to="/Login">Login</router-link>
    </nav> -->
    <router-view />
  </div>
</template>
<style>
html,
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.app {
  background-image: url("./assets/macos-monterey-1200.jpg");
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
