<template>
  <h1>Test - App Files</h1>
  <a>component is running</a>
  <a>Notes: Hier solen ordner hin kommen wo man sich den code ansehen kann</a>
</template>
<script lang="ts">
export default {
  name: "AppFiles",
};
</script>
