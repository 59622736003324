<template>
  <div class="menubar">
    <router-link to="/">Home</router-link>
    <router-link to="/Login">Login</router-link>
  </div>
  <div class="login">
    <img class="profile-img" alt="profile-img" />
    <input type="text" placeholder="Name" />
    <input type="text" placeholder="Passwort" />
  </div>
</template>

<style scoped>
body,
html {
  height: 100%;
  margin: 0;
}
.login {
  padding-top: 100px;
  justify-content: center;
  align-content: center;
  height: 100%;
  animation: transitionIn 0.75s;
}
.menubar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: right;
  background: rgba(236, 235, 235, 0.268);
  backdrop-filter: blur(10px);
  z-index: 1; /* Stelle sicher, dass die Menüleiste über den anderen Elementen liegt */
  padding: 4px;
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }

  to {
    opacity: 1;
    transform: rotateX(0);
  }
}
</style>
