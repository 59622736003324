<template>
  <div>
    <!-- <h1 class="app-name">{{ appName }}</h1> -->
  </div>
  <footer>
    <a href="/" tabIndex="0"
      ><img
        class="bn46"
        src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
        alt="bn45"
    /></a>
    <a href="/" tabIndex="0"
      ><img
        class="bn45"
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png"
        alt="bn45"
    /></a>
  </footer>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "MeneuApp",
  props: {
    appName: {
      type: String as PropType<string>,
      required: true,
    },
  },
});
</script>

<style scoped>
.bn45 {
  width: 170px;
  height: 50px;
}
.bn46 {
  width: 150px;
  height: 50px;
}
</style>
