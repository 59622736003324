<template>
  <div class="menubar">
    <TimeView />
    <router-link to="/">Home</router-link>
    <router-link to="/Login">Login</router-link>
  </div>
  <div class="app-flexbox">
    <div class="app-files app-box">
      <AppWindow :msg="'Finder'" />
      <AppFiles />
    </div>
    <div class="app-stats app-box">
      <AppWindow :msg="'Stats'" />
      <AppStats />
    </div>
    <div class="app-apps app-box">
      <AppWindow :msg="'Launchpad'" />
      <AppApps />
    </div>
    <div class="app-timeline app-box">
      <AppWindow :msg="'TimeLine'" />
      <AppTimeLine />
    </div>
  </div>
  <PopupComponent v-if="shouldShowModal" appName="MenuApp" :showPopup="true" />
</template>

<script lang="ts">
import { defineComponent, ref, watch, onBeforeUnmount } from "vue";
import { useRoute } from "vue-router";
import AppWindow from "@/components/app-window.vue";
import AppFiles from "@/components/app-files.vue";
import AppStats from "@/components/app-stats.vue";
import AppApps from "@/components/app-apps.vue";
import AppTimeLine from "@/components/app-timeline.vue";
import TimeView from "@/components/functions/timeView.vue";
import PopupComponent from "@/components/popup.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    AppWindow,
    AppFiles,
    AppStats,
    AppApps,
    AppTimeLine,
    TimeView,
    PopupComponent,
  },
  setup() {
    const route = useRoute();
    const shouldShowModal = ref(false);

    // Hier könntest du deine statische Logik platzieren, um die Modalfunktion zu öffnen
    // ...
    // Beispiel: Wenn die Route /menuapp lautet, öffne das Modalfenster
    if (route.path === "/menuapp") {
      console.log("Opening MenuApp modal...");
      shouldShowModal.value = true;
    }
    // ...

    // Watch for changes in the route and update the modal state
    const unwatch = watch(
      () => route.params,
      (newParams) => {
        if (newParams.appName) {
          shouldShowModal.value = true;
        }
      },
      { immediate: true }
    );

    // Cleanup the watch on component unmount
    onBeforeUnmount(() => {
      unwatch();
    });

    return {
      shouldShowModal,
    };
  },
});
</script>

<style scoped>
body,
html {
  height: 100%;
  margin: 0;
}

.app-flexbox {
  display: grid;
  grid-template-columns: 40% 40%;
  grid-template-rows: 40vh 40vh;
  gap: 5%;
  justify-content: center;
  align-content: center;
  height: 100%;
  animation: transitionIn 0.75s;
}

.app-box {
  background: rgba(0, 0, 0, 0, 8);
  backdrop-filter: blur(10px);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.app-flexbox > div {
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 10px;
}

.menubar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: right;
  background: rgba(236, 235, 235, 0.268);
  backdrop-filter: blur(10px);
  z-index: 1; /* Stelle sicher, dass die Menüleiste über den anderen Elementen liegt */
  padding: 4px;
}
@media (max-width: 800px) {
  .app-flexbox {
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }

  .app-flexbox > div:not(.app-apps) {
    display: none;
  }

  .app-flexbox > div.app-apps {
    display: block;
    width: 80%;
    height: 90vh;
    margin-left: 10%;
    margin-right: 10%;
  }
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }

  to {
    opacity: 1;
    transform: rotateX(0);
  }
}
</style>
