<template>
  <div class="container">
    <div class="circle red">
      <slot name="red"><span class="symbol">X</span></slot>
    </div>
    <div class="circle yellow">
      <span class="symbol">-</span>
    </div>
    <div class="circle green">
      <slot name="green"><span class="symbol">+</span></slot>
    </div>
    <p class="app-name">{{ msg }}</p>
  </div>
</template>
<style scoped>
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  margin: 5px 0 0 0;
}

.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 5px;
}

.circle:last-child {
  margin-right: 0;
}

.circle:hover .symbol {
  opacity: 1;
}

.symbol {
  opacity: 0;
  color: white;
  font-weight: bold;
  font-size: 12px;
}

.red {
  background-color: #fa5b49;
}

.yellow {
  background-color: #f7bd0b;
}

.green {
  background-color: #2ecb4e;
}

.app-name {
  margin: 5px 0 0 0;
  text-align: center;
}
</style>
<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    msg: String,
  },
})
export default class AppWindow extends Vue {
  msg!: string;
}
</script>
