<template>
  <div>
    <p id="clock"></p>
  </div>
</template>
<style scoped>
#clock {
  color: black;
  margin: 0;
}
</style>
<script lang="ts">
export default {
  name: "TimeView",
};
function getCurrentTime(): string {
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
}

function updateClock() {
  const timeElement = document.getElementById("clock");
  if (timeElement) {
    timeElement.textContent = getCurrentTime();
  }
}

// Aktualisiere die Uhrzeit alle Sekunde
setInterval(updateClock, 1000);

// Initialisiere die Uhrzeit beim Laden der Seite
window.onload = updateClock;
</script>
