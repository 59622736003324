<template>
  <div>
    <h1 class="app-name">{{ appName }}</h1>
    <p>Wetter App wird hier angezeigt</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "WetterApp",
  props: {
    appName: {
      type: String as PropType<string>,
      required: true,
    },
  },
});
</script>
