import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16713a32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menubar" }
const _hoisted_2 = { class: "app-flexbox" }
const _hoisted_3 = { class: "app-files app-box" }
const _hoisted_4 = { class: "app-stats app-box" }
const _hoisted_5 = { class: "app-apps app-box" }
const _hoisted_6 = { class: "app-timeline app-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TimeView = _resolveComponent("TimeView")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AppWindow = _resolveComponent("AppWindow")!
  const _component_AppFiles = _resolveComponent("AppFiles")!
  const _component_AppStats = _resolveComponent("AppStats")!
  const _component_AppApps = _resolveComponent("AppApps")!
  const _component_AppTimeLine = _resolveComponent("AppTimeLine")!
  const _component_PopupComponent = _resolveComponent("PopupComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TimeView),
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createTextVNode("Home")
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/Login" }, {
        default: _withCtx(() => [
          _createTextVNode("Login")
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AppWindow, { msg: 'Finder' }),
        _createVNode(_component_AppFiles)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_AppWindow, { msg: 'Stats' }),
        _createVNode(_component_AppStats)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_AppWindow, { msg: 'Launchpad' }),
        _createVNode(_component_AppApps)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_AppWindow, { msg: 'TimeLine' }),
        _createVNode(_component_AppTimeLine)
      ])
    ]),
    (_ctx.shouldShowModal)
      ? (_openBlock(), _createBlock(_component_PopupComponent, {
          key: 0,
          appName: "MenuApp",
          showPopup: true
        }))
      : _createCommentVNode("", true)
  ], 64))
}