<template>
  <img
    :src="require(`@/assets/icons/${appName.toLowerCase()}.png`)"
    alt="App Icon"
    @click="openModal"
    style="cursor: pointer"
    class="app-icon"
  />
  <Teleport to="#modal">
    <div class="modal-wrap" v-if="isActive">
      <div class="modal" :class="{ 'full-screen': fullScreen }">
        <AppWindow>
          <template #red>
            <span class="symbol" @click="closeModal">X</span>
          </template>
          <template #green>
            <span class="symbol" @click="fullScreenModal">+</span>
          </template>
        </AppWindow>
        <component :is="currentAppComponent" :appName="appName">
          <h1>{{ appName }}</h1>
          <p>Failed To Load Media</p>
        </component>
        <!-- <button @click="closeModal">Close</button> -->
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { ref, defineComponent } from "vue";
import WetterApp from "@/components/apps/wetterApp.vue";
import TimeApp from "@/components/apps/TimeApp.vue";
import MenuApp from "@/components/apps/MenuApp.vue";
import AppWindow from "@/components/app-window.vue";
interface AppMap {
  [key: string]: any;
}

export default defineComponent({
  name: "PopupComponent",
  props: {
    appName: {
      type: String,
      required: true,
    },
    showPopup: {
      type: Boolean,
      required: true,
    },
  },
  setup(props: { appName: string; showPopup: boolean }) {
    const isActive = ref(false);
    const fullScreen = ref(false);

    const openModal = () => {
      isActive.value = true;
    };

    const closeModal = () => {
      isActive.value = false;
      fullScreen.value = false;
    };

    const fullScreenModal = () => {
      fullScreen.value = !fullScreen.value;
    };

    const appComponentMap: AppMap = {
      WetterApp,
      TimeApp,
      MenuApp,
      // Andere Apps hier aufführen
    };

    const currentAppComponent = appComponentMap[props.appName] || null;

    return {
      isActive,
      openModal,
      closeModal,
      fullScreen,
      fullScreenModal,
      currentAppComponent,
    };
  },
  components: {
    WetterApp,
    TimeApp,
    MenuApp,
    AppWindow,
    // Andere Apps hier aufführen
  },
});
</script>

<style scoped>
.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal {
  width: 80%;
  height: 80%;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  transition: width 0.3s ease, height 0.3s ease;
}

.full-screen {
  width: 100% !important;
  height: 100% !important;
}

.symbol {
  opacity: 0;
  color: white;
  font-weight: bold;
  font-size: 12px;
}

.circle:hover .symbol {
  opacity: 1;
}

.app-icon {
  width: 10%;
  height: 10%;
}
</style>
