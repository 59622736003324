<template>
  <h1>Test - App Apps</h1>
  <div class="popup-container">
    <PopupComponent
      v-for="(app, index) in appList"
      :key="index"
      :appName="app"
      :showPopup="activeApp.value === app"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
import PopupComponent from "@/components/popup.vue";

export default defineComponent({
  name: "AppApps",
  components: {
    PopupComponent,
  },
  setup() {
    const appList = ref<string[]>(["WetterApp", "TimeApp", "MenuApp"]);
    const activeApp = reactive({ value: "" });

    const openPopup = (appName: string) => {
      activeApp.value = appName;
    };

    const closePopup = () => {
      activeApp.value = "";
    };

    return {
      appList,
      activeApp,
      openPopup,
      closePopup,
    };
  },
});
</script>

<style scoped>
.popup-container {
  display: flex;
  gap: 16px;
  margin-left: 10px;
}
</style>
