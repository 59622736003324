<template>
  <div>
    <h1>MenuApp Page</h1>
  </div>
</template>

<script lang="ts">
import { ref, onMounted } from "vue";

export default {
  name: "MenuAppPage",
  setup() {
    // Hier könntest du die Funktion für die Modalfenster öffnen aufrufen
    onMounted(() => {
      console.log("Opening MenuApp modal...");
    });

    return {};
  },
};
</script>
